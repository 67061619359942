import Loadable from 'layouts/full/shared/loadable/Loadable';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

/* ****Pages***** */
// Private pages
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const Analytics = Loadable(lazy(() => import('pages/analytics')));
const Transaction = Loadable(lazy(() => import('pages/transaction')));
const TransactionDetail = Loadable(lazy(() => import('pages/transaction/detail')));
const QrisPayment = Loadable(lazy(() => import('pages/transaction/qris-payment')));
const QrisPaymentDetail = Loadable(lazy(() => import('pages/transaction/qris-payment/detail')));
const CheckQris = Loadable(lazy(() => import('pages/transaction/qris-payment/check-qr')));
const ChangePassword = Loadable(lazy(() => import('pages/changepassword')));
const Settlement = Loadable(lazy(() => import('pages/settlement')));
const SettlementDetail = Loadable(lazy(() => import('pages/settlement/detail')));
const Logs = Loadable(lazy(() => import('pages/logs')));
const PaymentLink = Loadable(lazy(() => import('pages/payment-link')));
const PaymentLinkDetail = Loadable(lazy(() => import('pages/payment-link/[id]')));
const Member = Loadable(lazy(() => import('pages/member')));
const MemberDetail = Loadable(lazy(() => import('pages/member/[id]')));
const Merchant = Loadable(lazy(() => import('pages/merchant')));
const MerchantDetail = Loadable(lazy(() => import('pages/merchant/detail-merchant')));
const CreateMerchant = Loadable(lazy(() => import('pages/merchant/create-merchant')));
const Business = Loadable(lazy(() => import('pages/business')));
const BusinessDetail = Loadable(lazy(() => import('pages/business/detail')));
const CreateBusiness = Loadable(lazy(() => import('pages/business/create')));
const Checkout = Loadable(lazy(() => import('pages/checkout/[id]')));
const CreatePaymentLink = Loadable(lazy(() => import('pages/create-payment-link')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const User = Loadable(lazy(() => import('pages/user')));
const RegisterFlow = Loadable(lazy(() => import('pages/register-flow')));
const RegisterFormEnterprise = Loadable(lazy(() => import('pages/register-form/enterprise')));
const RegisterFormPersonal = Loadable(lazy(() => import('pages/register-form/personal')));
const Settings = Loadable(lazy(() => import('pages/settings')));
const CreateWebhook = Loadable(lazy(() => import('pages/settings/create-webhook')));
const InviteNewAdmin = Loadable(lazy(() => import('pages/invite-new-admin')));
const VA = Loadable(lazy(() => import('pages/virtual-account')));
const VADetail = Loadable(lazy(() => import('pages/virtual-account/[id]')));
const Withdrawal = Loadable(lazy(() => import('pages/withdrawal')));
const CreateWithdrawal = Loadable(lazy(() => import('pages/withdrawal/createWithdrawal')));
const WithdrawalDetail = Loadable(lazy(() => import('pages/withdrawal/detail')));
const CheckReconcile = Loadable(lazy(() => import('pages/secret-page/check-reconcile')));

// Public pages
const Register = Loadable(lazy(() => import('pages/Authentication/register')));
const Login = Loadable(lazy(() => import('pages/Authentication/login')));
const VerificationEmail = Loadable(lazy(() => import('pages/Authentication/verification-email')));
const VerificationEmailCheck = Loadable(
  lazy(() => import('pages/Authentication/verification-email-check')),
);
const ForgotPassword = Loadable(lazy(() => import('pages/Authentication/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('pages/Authentication/reset-password')));
const ResendEmail = Loadable(lazy(() => import('pages/Authentication/resend-email')));

const authProtectedRoutes = [
  { path: '/', element: <Navigate to="/dashboard" /> },
  { path: '/dashboard', exact: true, element: <Dashboard /> },
  { path: '/analytics', element: <Analytics /> },
  { path: '/changepassword', element: <ChangePassword /> },
  { path: '/transaction', element: <Transaction /> },
  { path: '/qris-payment', element: <QrisPayment /> },
  { path: '/qris-payment/:id', element: <QrisPaymentDetail /> },
  { path: '/qris-payment/check-status-qr', element: <CheckQris /> },
  { path: '/transaction/:businessId/:id', element: <TransactionDetail /> },
  { path: '/settlement', element: <Settlement /> },
  { path: '/settlement/detail', element: <SettlementDetail /> },
  { path: '/logs', element: <Logs /> },
  { path: '/payment-link', element: <PaymentLink /> },
  { path: '/payment-link/:id', element: <PaymentLinkDetail /> },
  { path: '/create-payment-link', element: <CreatePaymentLink /> },
  { path: '/user', element: <Member /> },
  { path: '/user/:id', element: <MemberDetail /> },
  { path: '/merchant', element: <Merchant /> },
  { path: '/merchant/:id', element: <MerchantDetail /> },
  { path: '/merchant/edit-merchant/:merchantId', element: <CreateMerchant /> },
  { path: '/business', element: <Business /> },
  { path: '/business/:id', element: <BusinessDetail /> },
  { path: '/business/create-business', element: <CreateBusiness /> },
  { path: '/business/:id/create-merchant', element: <CreateMerchant /> },
  { path: '/profile', element: <Profile /> },
  { path: '/add-user', element: <User /> },
  { path: '/register-flow', element: <RegisterFlow /> },
  { path: '/register-form/enterprise', element: <RegisterFormEnterprise /> },
  { path: '/register-form/personal', element: <RegisterFormPersonal /> },
  { path: '/settings', element: <Settings /> },
  { path: '/settings/create-webhook', element: <CreateWebhook /> },
  { path: '/invite-new-admin', element: <InviteNewAdmin /> },
  { path: '/virtual-account', element: <VA /> },
  { path: '/virtual-account/:id', element: <VADetail /> },
  { path: '/withdrawal', element: <Withdrawal /> },
  { path: '/withdrawal/create-withdrawal', element: <CreateWithdrawal /> },
  { path: '/withdrawal/detail/:id', element: <WithdrawalDetail /> },
  { path: '/check-reconcile', element: <CheckReconcile /> },
];

const publicRoutes = [
  { path: '/register', element: <Register /> },
  { path: '/login', element: <Login /> },
  { path: '/verification-email/:token', element: <VerificationEmail /> },
  { path: '/verification-email-check', element: <VerificationEmailCheck /> },
  { path: '/checkout/:id', element: <Checkout /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:token', element: <ResetPassword /> },
  { path: '/resend-email', element: <ResendEmail /> },
];

export { authProtectedRoutes, publicRoutes };
